define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/models/datatables/BaseModel',

  'modules/shop.cash-register-retail/views/datatables/table/bodyCell',
  'modules/shop.cash-register-retail/views/datatables/table/headerCell',
], (
  $, _, Backbone, BaseModel,
  BodyRowCell, HeaderRowCell,
) => BaseModel.extend({
  defaults: {
    name: 'column',
    default: '',
    path: null,
    translationPath: null, // if true, it tries to get translation.<path>. If string it tries to get the translation from that path
    sortName: null,
    sortDir: null,
    bodyCellRenderIsSafe: false,
    bodyCellRenderFn: null,
    bodyCellCls: null,
    bodyCellClassName: null,
    bodyCellOptions: null,
    headerCellCls: null,
    headerCellOptions: null,
    headerCellClassName: null,
    footerCellRenderIsSafe: false,
    footerCellCls: null,
    footerCellOptions: null,
    footerCellClassName: null,
    visible: true,
  },

  initialize() {
    if (!this.has('bodyCellRenderFn')) {
      this.set('bodyCellRenderFn', this._bodyCellRender);
    }
  },

  _bodyCellRender(data, model) {
    if (_.isNumber(data)) {
      return data;
    }
    return data || this.get('default');
  },

  getBodyCellContent(data, model) {
    const renderFn = this.get('bodyCellRenderFn');
    let content = this._bodyCellRender.call(this, data, model);
    // Is the 'bodyCellRenderFn' is set it will call the render function to render the data.
    if (_.isFunction(renderFn)) {
      content = renderFn.call(this, data, model);
    }

    return content;
  },

  getBodyCellOptions(additionalOptions) {
    const options = _.extend({}, additionalOptions, {
      columnDefinitionModel: this,
    });

    let viewOptions = options;
    if (this.has('bodyCellOptions') && _.isObject(this.get('bodyCellOptions'))) {
      const bodyCellOptions = this.get('bodyCellOptions');
      if (_.isFunction(bodyCellOptions)) {
        viewOptions = _.extend({}, bodyCellOptions(this), options);
      } else if (_.isObject(bodyCellOptions)) {
        viewOptions = _.extend({}, bodyCellOptions, options);
      }
    }

    return viewOptions;
  },

  getBodyCellCls() {
    let viewCls = BodyRowCell;
    if (this.has('bodyCellCls')) {
      viewCls = this.get('bodyCellCls');
    }

    return viewCls;
  },

  getHeaderCellOptions(additionalOptions) {
    const options = _.extend({}, additionalOptions, {
      columnDefinitionModel: this,
    });

    let viewOptions = options;
    if (this.has('headerCellOptions') && _.isObject(this.get('headerCellOptions'))) {
      viewOptions = _.extend({}, this.get('headerCellOptions'), options);
    }

    return viewOptions;
  },

  getHeaderCellCls() {
    let viewCls = HeaderRowCell;
    if (this.has('headerCellCls')) {
      viewCls = this.get('headerCellCls');
    }

    return viewCls;
  },
  getFooterCellOptions(additionalOptions) {
    const options = _.extend({}, additionalOptions, {
      columnDefinitionModel: this,
    });

    let viewOptions = options;
    if (this.has('footerCellOptions') && _.isObject(this.get('footerCellOptions'))) {
      viewOptions = _.extend({}, this.get('footerCellOptions'), options);
    }

    return viewOptions;
  },

  getFooterCellCls() {
    let viewCls = null;
    if (this.get('footerCellCls')) {
      viewCls = this.get('footerCellCls');
    }
    return viewCls;
  },

  getFooterCellContent(footerCellView, data) {
    const renderFn = this.get('footerCellRenderFn');
    let content = data;
    if (_.isFunction(renderFn)) {
      content = renderFn.call(this, footerCellView, data);
    }
    if (!content && this.has('footerDefault')) {
      content = this.get('footerDefault');
    }
    return content;
  },

}));
