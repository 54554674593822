define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/models/ipc/ipc',
  'modules/shop.cash-register-retail/models/settings/terminal',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',

  'modules/shop.cash-register-retail/views/popups/ccvUnknownTransactionResultPopup',
], (
  $, _, Backbone, Locale, IPC, TerminalSetting, PaymentMethodsSetting,
  CCVUnknownTransactionResultPopupView,
) => {
  const Model = Backbone.Model.extend({
    isAvailable() {
      return IPC.isAvailable();
    },

    setupTerminal(name, ipAddress) {
      const def = $.Deferred();

      const unknownError = () => def.reject({
        error: Locale.translate("could_not_set_up_ccv_pin_terminal"),
      });

      if (!IPC.isAvailable()) {
        console.warn('[CCV_PIN] Could not setup terminal, IPC is not available');
        return unknownError();
      }

      IPC.ipcSend('payment_setup_pin_terminal', {
        name,
        ipAddress,
      }).then(() => {
        console.debug(`[CCV_PIN] Terminal setup (name=${name}, ipAddress=${ipAddress})`);
        this.ready = true;
        def.resolve();
      }, () => {
        unknownError();
      });

      return def;
    },

    removePinTerminal() {
      const def = $.Deferred();

      const unknownError = () => def.reject({
        error: Locale.translate("could_not_remove_ccv_pin_terminal"),
      });

      if (!this.isAvailable()) {
        console.warn('[CCV_PIN] Could not remove terminal, IPC is not available');
        return unknownError();
      }

      IPC.ipcSend('payment_remove_pin_terminal').then(() => {
        console.debug('[CCV_PIN] Terminal removed');
        this.ready = false;
        def.resolve();
      }, () => {
        unknownError();
      });

      return def;
    },

    updatePinTerminal() {
      const def = $.Deferred();

      if (this.isAvailable() && TerminalSetting.isCCVPin()) {
        const model = PaymentMethodsSetting.getTerminalDevicesCollection().get(TerminalSetting.get('id'));
        if (model) {
          TerminalSetting.setTerminalByModel(model); // TODO: Make it so the terminal always updates
        }

        const ipAddress = TerminalSetting.get('extra.local_ip');
        const name = TerminalSetting.get('title');

        this.setupTerminal(name, ipAddress).then(def.resolve, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    checkOpenTransaction() {
      const def = $.Deferred();

      if (this.isAvailable() && TerminalSetting.isCCVPin()) {
        IPC.ipcSend('payment_check_open_transaction').then((transaction) => {
          if (!transaction) {
            // There was no open transaction.
            // def.resolve();
            return;
          } else {
            console.log('[CCV_PIN] Open transaction found', transaction);
          }
          def.resolve();
        }, def.reject);

        // const ccv = new CCVUnknownTransactionResultPopupView();
        // ccv.open();
        // console.log(ccv);
      } else {
        def.resolve();
      }

      return def;
    },
  });

  return new Model();
});
