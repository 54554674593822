define([
  'jquery',
  'modules/shop.cash-register-retail/controllers/default/main',
  'modules/shop.cash-register-retail/views/payments/layout',
  'modules/shop.cash-register-retail/views/success/layout',

  'modules/shop.cash-register-retail/views/checkouts/retail',
  'modules/shop.cash-register-retail/views/checkouts/hospitality',

  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/models/keyboard',
  'modules/common/components/historyBreadcrumb',

  'modules/shop.cash-register-retail/collections/loadable/productionGroups',
  'modules/shop.cash-register-retail/collections/TaxRate',
  'upx.modules/ShopModule/models/Repair',
], (
  $, DefaultController, PaymentView, SuccessView,
  RetailCheckoutView, HospitalityCheckoutView,
  DeviceConfig, Keyboard, HistoryBreadcrumb,
  ProductionGroups, TaxRate, RepairModel,
) => DefaultController.extend({

  checkout() {
    ProductionGroups.loadPromise().then(() => {
      // reset any keyboard mode
      Keyboard.resetMode();

      // Render view
      const View = this.getCheckoutView();
      this.getRegionByPath('main.page.content').show(new View());
    }).catch(console.error);
  },

  async checkoutTable(tableId) {
    const View = this.getCheckoutView();
    const def = this.handleLoadingView(View);
    try {
      // reset any keyboard mode
      Keyboard.resetMode();

      // Done
      def.resolve({ tableId });
    } catch (e) {
      def.reject(e.message);
    }
  },
  async checkoutRepair(repairId) {
    const View = this.getCheckoutView();
    const def = this.handleLoadingView(View);
    try {
      // reset any keyboard mode
      Keyboard.resetMode();

      // Done
      def.resolve({ repairId });
    } catch (e) {
      def.reject(e.message);
    }
  },

  getCheckoutView() {
    const mode = DeviceConfig.getDeviceMode();

    let View = RetailCheckoutView; // default to retail

    if (
      mode === DeviceConfig.MODE_Retail
                || mode === DeviceConfig.MODE_Salon
    ) {
      View = RetailCheckoutView;
    } else if (mode === DeviceConfig.MODE_Hospitality) {
      View = HospitalityCheckoutView;
    } else {
      console.error(`Unknown checkout mode = ${mode}`);
    }

    return View;
  },

  handlePaymentView(options = {}) {
    const def = this.handleLoadingView(PaymentView);
    TaxRate.load().then(
      () => def.resolve(options),
      def.reject,
    );
  },

  payment() {
    this.handlePaymentView();
  },

  quickPayment() {
    this.handlePaymentView({
      isQuick: true,
    });
  },

  // TODO [ian@8/14/20]: Remove when removing tabs
  paymentTab(tabId) {
    this.handlePaymentView({
      tabId,
    });
  },

  paymentTable(tableId) {
    this.handlePaymentView({
      tableId,
    });
  },
  paymentRepair(repairId) {
    const model = new RepairModel({ id: repairId });
    const def = this.handleLoadingView(PaymentView);
    $.when(model.fetch(), TaxRate.load()).then(() => {
      def.resolve({
        repairModel: model,
      });
    }, def.reject);
  },

  success() {
    const backRoute = HistoryBreadcrumb.peepBackRoute();
    if (backRoute === 'checkout') {
      // never straight from checkout, it will clean the order when success view is destroyed
      HistoryBreadcrumb.goBack();
    } else {
      this.getRegionByPath('main.page.content').show(new SuccessView());
    }
  },

}));
