define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/models/EmployeeCalendar',
], (
  $, _, Backbone, CalendarModel,
) => CalendarModel.extend({

}));
