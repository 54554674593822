define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/models/ipc/ipc',
  'modules/shop.cash-register-retail/models/settings/terminal',
  'modules/shop.cash-register-retail/models/ccv/ccvPin',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/shop.cash-register-retail/components/printing',

  './currentCCVTransaction',
], (
  $, _, Backbone, Locale, IPC, TerminalSetting, CCVPin, ReceiptPrinter, Printing,
  CurrentCCVTransaction,
) => Backbone.Model.extend({

  initialize(options) {
    this.paymentId = options.paymentId;
    this.outputCallbacks = [];
    this.outputListenerAdded = false;
    this.hasStarted = false;
  },

  startPayment(paymentModel) {
    const def = $.Deferred();
    const logPrefix = `[CCVPinPayment ID:${paymentModel.id}]`;

    const unknownError = () => def.reject({
      error: Locale.translate("could_not_start_ccv_pin_payment"),
    });

    if (!CCVPin.isAvailable()) {
      console.warn(`${logPrefix} Could not start payment, IPC is not available`);
      return unknownError();
    }

    const ipAddress = TerminalSetting.get('extra.local_ip');
    const terminalName = TerminalSetting.get('title');

    ReceiptPrinter.isPrinterAvailable().then((isAvailable) => {
      // TODO: Better printer status like paperlow and paperempty
      const printerStatus = ReceiptPrinter.isWantedType() && isAvailable ? 'Available' : 'Unavailable';

      const data = {
        paymentId: this.paymentId,
        amount: paymentModel.amount,
        currency: paymentModel.currency_iso3,
        hookUpdateUrl: paymentModel.metadata.hook_update_url,
        terminal: {
          name: terminalName,
          ipAddress,
        },
        printerStatus,
      };

      IPC.ipcSend('payment_start', data).then(() => {
        console.debug(`${logPrefix} Payment started`);
        this.hasStarted = true;

        CurrentCCVTransaction.setInProgress(this.paymentId, paymentModel);

        def.resolve();
      }).catch((error) => {
        if (error && error.name === 'CCVError') {
          if (error.code === 'device_unreachable') {
            console.warn(`${logPrefix} Could not start payment, device is unreachable`);
            return def.reject({
              error: Locale.translate("pin_terminal_could_not_be_reached_is_it_online_question"),
            });
          }
        }
        return unknownError();
      });
    }, def.reject);

    return def;
  },

  startRecovery() {
    const def = $.Deferred();
    const logPrefix = '[CCVPinPayment]';

    if (!this.hasStarted) {
      console.warn(`${logPrefix} Tried to recover unstarted payment (id=${this.paymentId})`);
      return def.reject({
        error: Locale.translate("there_is_no_payment_to_recover"),
      });
    }

    IPC.ipcSend('payment_start_recovery', {
      originalPaymentId: this.paymentId,
    }).then((result) => {
      console.log(`${logPrefix} Transaction recovered`, result);
      def.resolve(result);
    }, (err) => {
      console.error(`${logPrefix} Could not recover transaction`, err);
      def.reject({
        error: Locale.translate("could_not_recover_transaction"), // TODO: Better message?
      });
    });

    return def;
  },

  cancelPayment() {
    const def = $.Deferred();
    const logPrefix = '[CCVPinPayment]';

    if (!this.hasStarted) {
      console.warn(`${logPrefix} Tried to cancel unstarted payment (id=${this.paymentId})`);
      return def.reject({
        error: Locale.translate("there_is_no_payment_to_cancel"),
      });
    }

    IPC.ipcSend('payment_abort').then(() => {
      console.log(`${logPrefix} Payment cancelled`);
      def.resolve();
    }, () => {
      def.reject({
        error: Locale.translate("could_not_cancel_ccv_payment"),
      });
    });

    return def;
  },

  onOutput(callback) {
    this.outputCallbacks.push(callback);

    if (!this.outputListenerAdded) {
      this.outputListenerAdded = true;
      IPC.ipcOn('payment_device_output', async (data) => {
        for (const cb of this.outputCallbacks) {
          if (data.paymentId === this.paymentId) {
            await cb(data);
          }
        }
      });
    }
  },

  onCashierDisplayOutput(callback) {
    const logPrefix = '[CCVPinPayment]';

    if (!CCVPin.isAvailable()) {
      console.warn(`${logPrefix} Could not listen to cashier display output, IPC is not available`);
      return;
    }

    this.onOutput((data) => {
      if (data.output.target === 'CashierDisplay') {
        const { textLines } = data.output;

        const line1 = textLines[0];
        const line2 = textLines[1];
        const line1Text = line1 ? line1.text : '';
        const line2Text = line2 ? line2.text : '';

        callback(line1Text, line2Text);
      }
    });
  },

  onFinished(callback) {
    IPC.ipcOn('payment_finished', (data) => {
      if (data.paymentId === this.paymentId) {
        CurrentCCVTransaction.transactionDone();
        callback(data.result);
      }
    });
  },

  onUnknownResult(callback) {
    IPC.ipcOn('payment_unknown_result', (data) => {
      if (data.paymentId === this.paymentId) {
        callback(data.result);
      }
    });
  }
}));
