define([
  'jquery',
  'underscore',
  'backbone',
  './abstractIpc',
], (
  $, _, Backbone, AbstractIPC,
) => {
  const Model = AbstractIPC.extend({
    isAvailable() {
      return this.available;
    },
    async setup() {
      if (!window.promiseIpc) {
        console.warn('[ELECTRON_IPC] window.promiseIpc is not defined, the electron app may be outdated.');
      }
      const pingResult = await this.ipcSend('ping');
      if (pingResult === 'pong') {
        this.available = true;
      } else {
        console.warn('[ELECTRON_IPC] ping failed, the electron app may be outdated.');
      }
    },
    ipcOn(event, callback) {
      window.promiseIpc.on(event, (e) => {
        const cbValue = callback(e);

        return Promise.resolve(cbValue);
      });
    },
    ipcOff(event) {
      window.promiseIpc.off(event);
    },
    ipcSend(event, data) {
      const ipc = new window.promiseIpc.PromiseIpc({
        // maxTimeoutMs: 10000,
      });
      return ipc.send(event, data);
    },
  });
  return new Model();
});
