define([
  'modules/common/crontabs/cron',
  'modules/shop.cash-register-retail/models/notifications',
],
(Cron, NotificationsModel) => Cron.extend({
  cron: '* * * * *', // every minute

  run() {
    NotificationsModel.fetchNotifications();
  },

}));
