define([
  'modules/shop.cash-register-retail/controllers/default/main',
  'modules/shop.cash-register-retail/views/repairs/overview/layout',
  'modules/shop.cash-register-retail/views/repairs/details/layout',
  'modules/shop.cash-register-retail/views/repairs/create.js',

  'upx.modules/ShopModule/models/Repair',
],
(
  DefaultController, ListView, DetailsView, CreateView,
  Model,
) => DefaultController.extend({

  list() {
    this.getRegionByPath('main.page.content').show(new ListView());
  },

  create() {
    this.getRegionByPath('main.page.content').show(new CreateView());
  },

  get(id) {
    const def = this.handleLoadingView(DetailsView);
    const model = new Model({ id });
    model.fetch().then(() => {
      def.resolve({
        model,
      });
    }, def.reject);
  },
}));
