define([
  'modules/shop.cash-register-retail/controllers/default/main',
  'modules/shop.cash-register-retail/views/products/catalog/layout',
],
(
  DefaultController, CatalogView,
) => DefaultController.extend({

  catalog() {
    this.getRegionByPath('main.page.content').show(new CatalogView());
  },
}));
