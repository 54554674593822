define([
  'jquery',
  'backbone',
  'modules/shop.cash-register-retail/controllers/default/fullscreen',
  'modules/shop.cash-register-retail/views/customerScreens/layout'
  ],
(
  $, Backbone, DefaultController,
  CustomerScreens,
) => DefaultController.extend({

  preview(path) {
    this.getView(path)
      .then((view) => {
        this.getRegionByPath('main.page').show(view);
      });
  },

  getView(path) {
    const def = new $.Deferred();

    if (path.startsWith('customer-screen')) {
      const shopId = path.split('::')[1];
      def.resolve(new CustomerScreens({
        shopId,
        forceScreen: 'order',
      }));
    }

    return def;
  },

}));
