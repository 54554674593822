define([
  'backbone',
  'modules/shop.cash-register-retail/controllers/default/main',
  'modules/shop.cash-register-retail/views/stickerPrint/swappable',
],
(
  Backbone, DefaultController, SwappableView,
) => DefaultController.extend({

  stickerPrint() {
    this.getRegionByPath('main.page.content')
      .show(new SwappableView());
  },

}));
