define([
  'modules/shop.cash-register-retail/controllers/default/main',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/views/dailyReports/swappable',
], (
  DefaultController, Locale,
  DailyReportsLayout,
) => DefaultController.extend({

  dailyReports(swapTo, id) {
    this.getRegionByPath('main.page.content')
      .show(new DailyReportsLayout({
        swapTo,
        id,
      }));
  },

}));
