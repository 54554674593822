define([
  'jquery',
  'underscore',
  'backbone',
  './abstractIpc',
], (
  $, _, Backbone, AbstractIPC,
) => {
  const Model = AbstractIPC.extend({
    isAvailable() {
      // There is never IPC available in default mode
      return false;
    },
    setup() { },
    ipcOn() {
      console.warn('[IPC] Method "ipcOn" is called on default mode');
    },
    ipcOff() {
      console.warn('[IPC] Method "ipcOff" is called on default mode');
    },
    ipcSend() {
      console.warn('[IPC] Method "ipcSend" is called on default mode');
    },
  });
  return new Model();
});
