define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.common/components/mode',

  './ipcElectron',
  './ipcDefault',
], (
  $, _, Backbone, Mode,
  IPCElectron, IPCDefault,
) => {
  if (Mode.isInElectronMode()) {
    return IPCElectron;
  }

  return IPCDefault;
});
