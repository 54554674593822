define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/components/customer',
], (
  $, _, Backbone,
  CustomerComponent,
) => Backbone.DeepModel.extend({

  unload() {
    this.clear();
  },

  selectByRelationDataId(relation_data_id) {
    const def = new $.Deferred();
    CustomerComponent.getFullInformation({ relation_data_id }).then(
      (model) => {
        this.updateFromData(model.toJSON());
        def.resolve();
      },
      def.reject,
    );
    return def;
  },

  updateFromData(data) {
    this.clear();
    this.set(data);
  },
  updateFromModel(model) {
    this.updateFromData(model.toJSON());
  },

}));
