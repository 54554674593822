define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/appLocalStorage',
], (
  $, _, Backbone, AppLocalStorage,
) => {
  const Model = Backbone.Model.extend({

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/models/currentCCVTransaction'),

    initialize() {
      this.fetch();
    },

    fetch() {
      Backbone.DeepModel.prototype.fetch.call(this)
        .then(() => {
          this.set(this.get('0'));
          this.unset('0');
        });
    },

    setInProgress(paymentId, payment) {
      this.set({
        paymentId,
        payment,
      });
      this.save();
    },

    transactionDone() {
      this.unset('paymentId');
      this.unset('payment');
    },

    isInProgress() {
      return this.has('paymentId') && this.has('payment');
    },
  });

  return new Model();
});
