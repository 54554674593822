define([
  'jquery',
  'underscore',
  'backbone',

  'modules/admin/controllers/default',
  'modules/shop.cash-register-retail/views/layouts/app/layout',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/models/customerScreenData',

  'modules/shop.cash-register-retail/views/error',
], (
  $, _, Backbone,
  Controller, Layout,
  Locale, CashRegisterApi, CustomerScreenData,
  ErrorView,
) => Controller.extend({
  mainLayout: Layout,

  mainLayoutOptions: {

  },

  before() {
    CustomerScreenData.removeCustomerInformation();
    CashRegisterApi.logAction('ROUTE');
    this.renderLayout([{
      regionName: 'main',
      layoutView: this.mainLayout,
      options: this.mainLayoutOptions,
    }]);
  },

  /**
         * This function accepts a ViewClass and returns a Deferred object.
         * If the deferred object is resolved, the options passed to resolve it will push into the ViewClass
         * If the deferred object is rejected, the options passed are used to show the user an error has occurred.
         * @param ViewClass
         * @return {jQuery.Deferred|jQuery.Deferred|{}|jQuery.Deferred|jQuery.Deferred|jQuery.Deferred|*}
         */
  handleLoadingView(ViewClass) {
    const def = new $.Deferred();

    // hash to check if the route has changed
    const startHash = location.hash;

    // Show a loader
    const contentRegion = this.getRegionByPath('main.page.content');
    contentRegion.showLoader();

    def.then((options) => {
      // Check if the hash is still the same
      if (location.hash === startHash) {
        // On success, we show the page, with the options passed to the view.
        options = options || {};
        contentRegion
          .show(new ViewClass(options));
      }
    }, (resp) => {
      // Get the error message
      let errorMessage = resp;
      if (_.isObject(resp) && 'error' in resp) {
        errorMessage = resp.error;
      }

      // Show view with the error message
      contentRegion
        .show(new ErrorView({
          errorTitle: Locale.translate('an_error_occurred_during_loading_of_the_page_dot'),
          errorMessage,
        }));
    });

    return def;
  },

  /**
         * It just renders the view in the content
         * @param ViewClass
         * @param viewOptions
         */
  handleView(ViewClass, viewOptions) {
    viewOptions = viewOptions || {};
    this.getRegionByPath('main.page.content')
      .show(new ViewClass(viewOptions));
  },

}));
