define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/events/event',
], (
  $, _, Backbone, Event,
) => Event.extend({
  name: 'modules/shop.cash-register-retail/events/connection/internetConnected',
}));
