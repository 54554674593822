define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/controllers/default/main',

  'modules/shop.cash-register-retail/views/onlineFoodOrder/overview/layout',

  'modules/shop.cash-register-retail/collections/upx/OnlineFoodOrder',
], (
  $, _, Backbone, DefaultController,
  OrderView, OnlineFoodOrderCollection,
) => DefaultController.extend({

  async overview() {
    const def = this.handleLoadingView(OrderView);
    try {
      await Promise.all([
        OnlineFoodOrderCollection.loadPromise(),
      ]);

      def.resolve();
    } catch (e) {
      def.reject(e);
    }
  },

}));
