define([
  'backbone',
  'modules/shop.cash-register-retail/controllers/default/main',
  'modules/shop.cash-register-retail/views/orders/layout',
],
(
  Backbone, DefaultController, OrdersView,
) => DefaultController.extend({

  orders() {
    this.getRegionByPath('main.page.content').show(new OrdersView());
  },

  orderDetails(id) {
    this.getRegionByPath('main.page.content').show(new OrdersView({
      openOrderId: id,
    }));
  },
  reload() {
    this.getRegionByPath('main.page.content').reset();
    // So the change does not happen to quick
    setTimeout(() => {
      Backbone.history.navigate('orders', { trigger: true });
    });
  },
}));
